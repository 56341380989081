<section class="vh-100">
    <div class="container-fluid h-custom">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <!-- Imgagem Logo -->  
        <div class="col-md-8 col-lg-5 col-xl-3">
          <img src="../../assets/images/telebrasilia.png"
            class="img-fluid" alt="Sample image">
        </div>

        <!-- Login -->
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form [formGroup]="loginForm"  (ngSubmit)="onSubmit()"  *ngIf="login">
            <div class="form-outline mb-4">
              <input type="text" id="cnpj" name="cnpj" class="form-control" maxlength="14"
                placeholder="Digite o cnpj" formControlName="cnpj" (ngModelChange)="setCleanCnpj()" />
                <label class="text-danger"><strong> {{cnpj}} </strong></label>
            </div>
  
            <div class="form-outline mb-3">
              <input type="password" id="senha" name="senha" class="form-control"
                placeholder="Digite a senha" formControlName="senha" (ngModelChange)="setCleanSenha()"/>
                <label class="text-danger"><strong> {{senha}} </strong></label>
            </div>
              <form #login="ngForm" (ngSubmit)="setForgotPassword()">
                <button type="submit" class="btn btn-link"><strong> Esqueceu sua senha? </strong></button>
              </form>
            <div class="row d-flex justify-content-center">
              <button type="submit" class="btn btn-primary">Entrar</button>
            </div>
          </form>
          <br>
          <!-- Esqueceu a senha -->
          <form [formGroup]="loginForm"  (ngSubmit)="getPassword()" *ngIf="forgotPassword">
            <div class="form-outline mb-4" >
              <input type="text" id="cnpj" class="form-control" maxlength="14"
                placeholder="Digite o cnpj" name="cnpj" formControlName="cnpj" (ngModelChange)="setCleanCnpj()">
                <label class="text-danger"><strong> {{cnpj}} </strong></label>
              </div>
              <div class="row d-flex justify-content-center">
                <button type="submit" class="btn btn-primary" style="padding-left: 11rem; padding-right: 11rem;">Enviar</button>
              </div>
              <form (ngSubmit)="cancelarEnvioEmail()">
                <br>
              <div class="row d-flex justify-content-center"> 
                <button type="submit" class="btn btn-light" style="padding-left: 10rem; padding-right: 11rem;">Cancelar</button>
              </div>
              </form>     
            </form>
            <br>
            <div class="form-outline mb-4" *ngIf="showLoading">
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                </div>
              </div>
            </div>
          
            <div class="form-outline mb-4" *ngIf="sendPassord">
              <div class="d-flex justify-content-center">
                <p class="text-success"><strong> Senha enviada para o email {{ emailSended }}!</strong></p>
              </div>
              <form #homeLogin="ngForm" (ngSubmit)="setLogin()">
                <div class="d-flex justify-content-center">
                  <button type="submit" class="btn btn-link"><strong>Clique aqui para fazer login</strong></button>
                </div>
              </form>
            </div>

            <div class="d-flex justify-content-center" *ngIf="errorSendPassord">
              <h6><label class="text-danger"><strong>CNPJ não encontrado</strong></label></h6>
              <br>
            </div>
          <!-- Salvar senha -->
          <form  [formGroup]="loginForm"  (ngSubmit)="savePasswords()"  *ngIf="savePassword">
            <div class="form-outline mb-4">
              <input type="password" id="senha" name="senha" class="form-control form-control-lg"
                placeholder="Digite a senha" formControlName="senha" (ngModelChange)="setCleanSenha()">
                <label class="text-danger"><strong> {{senha}} </strong></label>
            </div>
            <div class="form-outline mb-3">
              <input type="password" id="confirmarSenha" name="confirmarSenha" class="form-control form-control-lg"
                placeholder="Repita a senha" formControlName="confirmarSenha" (ngModelChange)="setCleanConfirmarSenha()">
                <label class="text-danger"><strong> {{confirmarSenha}} </strong></label>
            </div>
            <div class="text-center text-lg-start mt-4 pt-2">
              <button type="submit" class="btn btn-primary btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem;">Salvar</button>
            </div>
          </form>
          <div class="form-outline mb-4" *ngIf="savedPassord">
            <br>
            <h5><label class="text-success">Senha salva com sucesso!</label></h5>
            <br>
            <form #homeLogin="ngForm" (ngSubmit)="setLogin()">
              <div class="text-left"><button type="submit"  class="btn btn-link" routerLink="/login" routerLinkActive="selected">Clique aqui para fazer login</button></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  
